import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const BeachHopPage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Beach Hop",
    location: "Maldives",
    industry: "Tourism",
    developmentType: "Website",
    scope: "Design and Development",
    businessObjectives:
      "To increase visibility to their target market and double revenue in six months.",
    desiredOutcomes:
      "To build a web presence where customers can see and book tours online. The website will expand their company's reach as well as double their bookings from local and international customers.",
    targetMarket: "Tourists",
    competition: "Local tour providers",
    competitiveAdvantage:
      "Only 1 of 2 beach tour specific touring companies. Beach Hop has exclusive rights to certain beaches.",
    timeFrame: "2 Months",
  }

  const userStories = {
    mustHaves: [
      "Ability to do basic tour booking online",
      "Testimonials section",
      "About Us Section",
      "Pricing Section",
      "Contact Us Section",
      "Modern design that matches corporate brand with local beach images",
    ],
    niceToHaves: [
      "Twitter feed integration to show latest Beach Hop news",
      "Robust booking system with integrated calendar and payments",
      "Chat bot integration to help with customer questions",
      "Newsletter email capture form",
    ],
    maybeLater: [
      "Live webcam of exclusive beaches",
      "User accounts for repeat clients",
      "Content management system integration",
      "Auto Responder series setup to drive more sales",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: ["Color scheme selection", "Header design", "Navigation design"],
      weeks: "Week 2",
    },
    iteration3: {
      stories: ["About Us Section design", "Feature section design"],
      weeks: "Week 3",
    },
    iteration4: {
      stories: ["Testimonial section design", "Booking section design"],
      weeks: "Week 4",
    },
    iteration5: {
      stories: [
        "Footer Design",
        "Miscellaneous page designs, i.e. privacy, terms, etc",
      ],
      weeks: "Week 5",
    },
    iteration6: {
      stories: ["Booking section form submission development and testing"],
      weeks: "Week 6",
    },
    iteration7: {
      stories: [
        "User Acceptance Testing",
        "Final tweaks and bug fixes before release",
      ],
      weeks: "Week 7",
    },
    iteration8: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 8",
    },
  }

  const technologies = {
    fullStack: false,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "Bootstrap",
      "JavaScript",
      "Gatsby",
      "Jest",
      "Git",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Beach Hop had a short deadline and a large list of stories (AKA feature requests).",
      win: (
        <>
          <p>
            This challenge is common amongst our clients as we encourage them to
            dream big and list out every possible feature they could want.
          </p>
          <p>
            We then used an agile approach to prioritize and size up the
            stories. This showed them what we could realistically complete in
            their time frame.
          </p>
          <p className="mb-0">
            Overcoming this challenge from the start helped to create
            predictable design and development iterations.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "The Beach Hop team wanted their landing page to contain several images that could negatively affect load times.",
      win: (
        <>
          <p>
            Tourism websites tend to focus on images to sell their experience
            packages. This can have a severe impact on page load times which
            negatively effects search rankings and overall user experience.
          </p>
          <p className="mb-0">
            We were able to solve this by using Gatsby's innovative image plugin
            to lazy-load all images. This reduced load times from 7s to 2s.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "Implementing a robust booking system with integrated calendar and payments.",
      win: (
        <>
          <p>
            Beach Hop had a significant time constraint when it came to their
            required release date. After prioritizing their stories we found
            that there would not be enough time to implement a robust booking
            system.
          </p>
          <p>
            The Beach Hop team realized that if they wanted to go live within 2
            months with all their required sections designed and developed,
            they'd have to settle for a basic booking system to start.
          </p>
          <p className="mb-0">
            The robust booking system was moved to the next release and Beach
            Hop was able to go live within the required deadline.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "The executive team at Beach Hop wanted to change the scope mid project by introducing a chat bot.",
      win: (
        <>
          <p>
            Being an agile development company, the Tactic Apps team welcomes
            changes mid development. We communicated with the CIO that any
            change requires flexibility in scope, budget and/or time frame.
          </p>
          <p className="mb-0">
            They selected flexibility in scope but after careful consideration,
            the chat bot was downgraded to a "nice to have" feature and was put
            off until the next release.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "Tactic Apps had one of our lead developers fall ill midway through the project.",
      win: (
        <>
          <p>
            Like any other company, our employees and contractors may fall ill
            or deal with family emergencies from time to time. This rarely
            effects the outcome of a project.
          </p>
          <p className="mb-0">
            Since Tactic Apps uses a agile approach, our next available software
            engineer was able to get up to speed in a matter of hours by combing
            through our iteration plans and standardized code base.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "1",
      hourly: "65",
      time: "2",
    },
  }

  const resultsImageNames = {
    results1: "Header",
    results2: "About Us",
    results3: "Features",
    results4: "Pricing",
    results5: "Testimonials",
    results6: "Contact Us",
    results7: "Navigation",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: { eq: "work/websites/beach-hop/beach-hop-hero.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-devices.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-landing_page.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-about_us.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-features.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-pricing.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-testimonials.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/websites/beach-hop/wireframes/beach-hop-contact_us.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-header.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-about.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-features.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-pricing.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-testimonials.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-contact.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: {
              eq: "work/websites/beach-hop/beach-hop-results-navigation.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Beach Hop Case Study"
            keywords={[`beach hop website case study`]}
            description="Beach Hop allows tourists to book beach tours in the Maldives so they can see the best hidden beaches not available to the public."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Website Case study"
            subTitle="Beach Hop"
            pageContext={pageContext}
            location={location}
            crumbLabel="Beach Hop"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default BeachHopPage
